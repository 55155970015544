import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { CustomerSearch } from './Components/Customer/CustomerSearch';
import Root from './Components/root';
import { Customer, loader as customerDetailsLoader } from './Components/Customer/Customer';
import { EntitlementDetails, loader as entitlementDetailsLoader } from './Components/Entitlement/EntitlementDetails';

//const vendorId: number = 1;
const vendorId: number = 2;

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <div>C'est une erreur</div>,
    children: [
      {
        path: "",
        element: <CustomerSearch vendorId={vendorId} browseSearch={false/*true*/} initWithSearch={false/*true*/} showAddCustomerAction={true} showFormTitle={true} />,
      },
      {
        path: "sentinel/customer",
        element: <CustomerSearch vendorId={vendorId} browseSearch={false/*true*/} initWithSearch={false/*true*/} showAddCustomerAction={true} showFormTitle={true} />,
      },
      {
        path: "sentinel/customer/:customerId/",
        element: <Customer vendorId={vendorId} />,
        loader: customerDetailsLoader,
      },
      {
        path: "sentinel/customer/crmid(:crmId)/",
        element: <Customer vendorId={vendorId} />,
        loader: customerDetailsLoader,
      },
      {
        path: "sentinel/customer/:customerId/entitlement",
        element: <EntitlementDetails vendorId={vendorId} />,
        loader: entitlementDetailsLoader,
      },
      {
        path: "sentinel/customer/crmid(:crmId)/entitlement",
        element: <EntitlementDetails vendorId={vendorId} />,
        loader: entitlementDetailsLoader,
      },
      {
        path: "sentinel/entitlements",
        element: <EntitlementDetails vendorId={vendorId} />,
      },
      {
        path: "sentinel/entitlements/:entitlementId/",
        element: <EntitlementDetails vendorId={vendorId} />,
        loader: entitlementDetailsLoader,
      },
      {
        path: "sentinel/entitlements/keyid(:keyId)/",
        element: <EntitlementDetails vendorId={vendorId} />,
        loader: entitlementDetailsLoader,
      },
      // {
      //   path: "customer",
      //   element: <CustomerSearch vendorId={vendorId} />,
      // },
      // {
      //   path: "customer/:customerId/",
      //   element: <Customer vendorId={vendorId} />,
      //   loader: customerDetailsLoader,
      // }
    ]
  }
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(<RouterProvider router={router} />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
