import * as React from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Title from '../Title';
import { Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

export interface ISearchParams {
    customerName: string,
    crmId: string,
    customerRefId: string
}

export interface CustomerSearchFormProps {
    customerName?: string | null | undefined,
    crmId?: string | null | undefined,
    refId?: string | null | undefined,
    customerRefId?: string | null | undefined,
    pageIndex?: number | null | undefined,
    pageSize?: number | null | undefined,
    sortCol?: string | null | undefined,
    sortOrder?: string | null | undefined,
    vendorId?: number | null | undefined,
    searchInProgress: boolean,
    browseSearch: boolean,
    showFormTitle: boolean,
    onSearch?: (searchParams: ISearchParams) => void,
}

export const CustomerSearchForm: React.FC<CustomerSearchFormProps> = (props) => {
    const navigate = useNavigate();
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => { event.preventDefault(); }

    const GoToSearch = () => {

        //debugger;
        if (props.browseSearch) {
            const myUrl = new URL("http://pourrien");
            if (customerName && customerName !== "") myUrl.searchParams.append("customerName", customerName);
            if (crmId && crmId !== "") myUrl.searchParams.append("crmId", crmId);
            if (customerRefId && customerRefId !== "") myUrl.searchParams.append("customerRefId", customerRefId);
            if (pageSize) myUrl.searchParams.append("pageSize", pageSize.toString());
            if (sortCol && sortCol !== "") myUrl.searchParams.append("sortCol", sortCol);
            if (sortOrder && sortOrder !== "") myUrl.searchParams.append("sortOrder", sortOrder);

            navigate(
                {
                    pathname: '/sentinel/customer',
                    search: myUrl.search
                });
        }
        else {
            if (props.onSearch) {
                props.onSearch({
                    customerName: customerName,
                    crmId: crmId,
                    customerRefId: customerRefId
                });
            }
        }
    }

    const [customerName, setCustomerName] = useState<string>("");
    const [crmId, setCrmId] = useState<string>("");
    const [customerRefId, setCustomerRefId] = useState<string>("");
    const [pageSize, setPageSize] = useState<number | undefined>(undefined);
    const [sortCol, setSortCol] = useState<string>("");
    const [sortOrder, setSortOrder] = useState<string>("");
    const [searchInProgress, setSearchInProgress] = useState<boolean>(true);

    useEffect(() => {
        setCustomerName(props.customerName ? props.customerName : "");
        setCrmId(props.crmId ? props.crmId : "");
        setCustomerRefId(props.customerRefId ? props.customerRefId : "");
        setPageSize(props.pageSize ? props.pageSize : undefined);
        setSortCol(props.sortCol ? props.sortCol : "");
        setSortOrder(props.sortOrder ? props.sortOrder : "");
        setSearchInProgress(props.searchInProgress);
    }, [props.crmId, props.customerName, props.customerRefId, props.pageIndex, props.pageSize, props.sortCol, props.sortOrder, props.searchInProgress]);

    return (
        <React.Fragment>
            <Box component="form" onSubmit={handleSubmit} noValidate>
                {
                    props.showFormTitle &&
                    <Title>Customer search</Title>
                }
                <Grid container spacing={3} rowSpacing={0.1}>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            disabled={searchInProgress}
                            id="customerName"
                            name="customerName"
                            label="Customer name"
                            fullWidth
                            autoComplete="customer-name"
                            variant="standard"
                            value={customerName}
                            onChange={(v) => setCustomerName(v.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            disabled={searchInProgress}
                            id="crmId"
                            name="crmId"
                            label="CRM Id"
                            fullWidth
                            autoComplete="crm-id"
                            variant="standard"
                            value={crmId}
                            onChange={(v) => setCrmId(v.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            disabled={searchInProgress}
                            id="customerRefId"
                            name="customerRefId"
                            label="Ref Id"
                            fullWidth
                            autoComplete="customer-ref-id"
                            variant="standard"
                            value={customerRefId}
                            onChange={(v) => setCustomerRefId(v.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} justifyContent="center">
                        <Button
                            disabled={searchInProgress}
                            type="submit"
                            fullWidth variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            onClick={() => {
                                GoToSearch();
                            }}>
                            Search
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </React.Fragment >
    );
}