import * as React from 'react';
import { useEffect, useState } from "react";
import Grid from '@mui/material/Grid';
import { ICustomerSearchShuttle, ISOC_listResponse, SocSentinelWebApi } from '../../Services/soc-sentinel-web-api';
import { useLocation, useNavigate } from "react-router-dom";
import { Paper, Skeleton } from '@mui/material';
import { CustomerList, IFilter, IPagination } from './CustomerList';
import useIds from '../../Services/idsHook';
import { CustomerSearchForm } from './CustomerSearchForm';
import useDocumentTitle from '../DocumentTitle';
import { MessageBox } from '../Tools/MessageBox';

export interface CustomerSearchProps {
  vendorId: number,
  defaultpageSize?: number,
  browseSearch: boolean,
  initWithSearch: boolean,
  showAddCustomerAction: boolean,
  showFormTitle: boolean
  //hideIfNoResult: boolean,
}

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const CustomerSearch: React.FC<CustomerSearchProps> = (props) => {
  const [getIds, secretKey] = useIds();
  const [listCustomers, setListCustomers] = useState<ISOC_listResponse>();
  const [showSkeleton, setshowSkeleton] = useState<boolean>(false);
  const [customerShuttle, setCustomerShuttle] = useState<ICustomerSearchShuttle>();
  const [alertMessage, setAlertMessage] = React.useState("");
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [searchInProgress, setSearchInProgress] = React.useState(false);
  const [defaultPageSize, setDefaultPageSize] = useState<number>();

  let query = useQuery();

  useDocumentTitle("Customer - Search");
  useEffect(() => {
    //debugger
    setListCustomers(undefined)
    const ids = getIds();
    let restAPI = new SocSentinelWebApi();

    let pageSize = query.get("pageSize") as unknown as number;
    let pageIndex = query.get("pageIndex") as unknown as number;

    pageSize = pageSize ? pageSize : (props.defaultpageSize ? props.defaultpageSize : 10);
    setDefaultPageSize(pageSize)

    let customerShuttle: ICustomerSearchShuttle = {
      authShuttle: { cryptedIds: ids.cryptedIds },
      customerName: query.get("customerName"),
      crmId: query.get("crmId"),
      refId: query.get("refId"),
      customerRefId: query.get("customerRefId"),
      pageIndex: pageIndex ? pageIndex : 0,
      pageSize: pageSize, //pageSize ? pageSize : (props.defaultpageSize ? props.defaultpageSize : 10),
      sortCol: query.get("sortCol"),
      sortOrder: query.get("sortOrder"),
      vendorId: props.vendorId
    }

    setCustomerShuttle(customerShuttle);

    if (ids) {
      if (props.initWithSearch) {
        search(customerShuttle);
        // setSearchInProgress(true);
        // restAPI.CustomerSearch(customerShuttle)
        //   .then((u) => {
        //     //debugger
        //     setListCustomers(u);
        //     setSearchInProgress(false);
        //   })
        //   .catch((a) => {
        //     debugger;
        //     setAlertMessage(a);
        //     setAlertOpen(true);
        //   });
      }
      else {
        let temp: ISOC_listResponse = {
          // instances: [],
          // totalCount: 0,
          // pageIndex: 0,
          // pageSize: props.defaultpageSize,
          // totalPages: 0,
          // sortCol?: string,
          // sortOrder?: string
        }
        setListCustomers(temp);
      }
    }

  }, [query]);


  const search = (customerShuttle: ICustomerSearchShuttle) => {

    // console.log("search");
    // console.log(customerShuttle);

    setshowSkeleton(true);
    let restAPI = new SocSentinelWebApi();
    setSearchInProgress(true);
    restAPI.CustomerSearch(customerShuttle)
      .then((u) => {
        //debugger
        //console.log(u);
        setListCustomers(u);
        setSearchInProgress(false);
        setshowSkeleton(false);
      })
      .catch((a) => {
        //debugger;
        setAlertMessage(a);
        setAlertOpen(true);
      });
  }

  const navigate = useNavigate();
  const GoToSearch = (filter?: IFilter, customerShuttle?: ICustomerSearchShuttle) => {

    //debugger
    // console.log("GoToSearch");
    // console.log(filter);
    // console.log(customerShuttle);

    if (props.browseSearch) {
      const myUrl = new URL("http://pourrien");
      if (customerShuttle?.customerName && customerShuttle?.customerName !== "") myUrl.searchParams.append("customerName", customerShuttle?.customerName);
      if (customerShuttle?.crmId && customerShuttle?.crmId !== "") myUrl.searchParams.append("crmId", customerShuttle?.crmId);
      if (customerShuttle?.customerRefId && customerShuttle?.customerRefId !== "") myUrl.searchParams.append("customerRefId", customerShuttle?.customerRefId);
      if (filter?.pagination?.page) myUrl.searchParams.append("pageIndex", filter.pagination?.page.toString());
      if (filter?.pagination?.rowsPerPage) myUrl.searchParams.append("pageSize", filter.pagination?.rowsPerPage.toString());
      if (filter?.sorting?.order) myUrl.searchParams.append("sortOrder", filter.sorting?.order);
      if (filter?.sorting?.orderBy) myUrl.searchParams.append("sortCol", filter.sorting?.orderBy);

      navigate(
        {
          pathname: '/sentinel/customer',
          search: myUrl.search
        });
    }
    else {
      if (customerShuttle) {
        //debugger
        customerShuttle.pageIndex = filter?.pagination?.page !== undefined ? filter?.pagination?.page : customerShuttle.pageIndex;
        customerShuttle.pageSize = filter?.pagination?.rowsPerPage !== undefined ? filter?.pagination?.rowsPerPage : customerShuttle.pageSize;
        customerShuttle.sortCol = filter?.sorting?.orderBy ? filter?.sorting?.orderBy : customerShuttle.sortCol;
        customerShuttle.sortOrder = filter?.sorting?.order ? filter?.sorting?.order : customerShuttle.sortOrder;

        search(customerShuttle);
      }
    }
  }

  const GoToReset = () => {
    const myUrl = new URL("http://pourrien");
    if (customerShuttle?.customerName && customerShuttle?.customerName !== "") myUrl.searchParams.append("customerName", customerShuttle?.customerName);
    if (customerShuttle?.crmId && customerShuttle?.crmId !== "") myUrl.searchParams.append("crmId", customerShuttle?.crmId);
    if (customerShuttle?.customerRefId && customerShuttle?.customerRefId !== "") myUrl.searchParams.append("customerRefId", customerShuttle?.customerRefId);
    navigate(
      {
        pathname: '/sentinel/customer',
        search: myUrl.search
      });

    window.location.reload();
  }

  const handleAlerteClose = () => {
    setAlertOpen(false);
  };

  return (
    <>
      <MessageBox open={alertOpen} onClose={handleAlerteClose} message={alertMessage} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
            <CustomerSearchForm
              customerName={customerShuttle?.customerName}
              crmId={customerShuttle?.crmId}
              refId={customerShuttle?.refId}
              customerRefId={customerShuttle?.customerRefId}
              pageIndex={customerShuttle?.pageIndex}
              pageSize={customerShuttle?.pageSize}
              sortCol={customerShuttle?.sortCol}
              sortOrder={customerShuttle?.sortOrder}
              vendorId={customerShuttle?.vendorId}
              searchInProgress={searchInProgress}
              browseSearch={props.browseSearch}
              showFormTitle={props.showFormTitle}
              onSearch={(searchParams) => {
                //console.log(searchParams);
                if (customerShuttle) {
                  customerShuttle.customerName = searchParams.customerName;
                  customerShuttle.crmId = searchParams.crmId;
                  customerShuttle.refId = searchParams.customerRefId;
                  customerShuttle.pageIndex = undefined;
                  //search(customerShuttle);
                  GoToSearch(undefined, customerShuttle);
                }
              }}
            />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
            {
              (!listCustomers || showSkeleton) &&
              <>
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
              </>
            }
            {
              listCustomers && !showSkeleton &&
              <CustomerList
                vendorId={props.vendorId}
                instanceCustomers={listCustomers}
                onFilterChange={(filter) => { GoToSearch(filter, customerShuttle); }}
                onListChange={() => { GoToReset(); }}
                defaultpageSize={defaultPageSize}
                showAddCustomerAction={props.showAddCustomerAction}
              />
            }
          </Paper>
        </Grid>
      </Grid>
    </>);
}